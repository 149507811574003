/* @flow */
import { gql } from "@apollo/client";

export default gql`
  mutation unpauseQueue {
    unpauseQueue {
      id
      queueStatus
    }
  }
`;
