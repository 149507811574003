/* @flow */
import { gql } from "@apollo/client";

export default gql`
  query loadComposer {
    accounts(onlyPostable: true) {
      id
      name
      nickname
      description
      image
      provider
      providerDisplayName
      platform
      pinterestBoards {
        id
        name
      }
      linkedinAdAccounts {
        id
        name
      }
      tiktokMeta{
        duetDisabled
        maxVideoPostDurationSec
        stitchDisabled
        commentDisabled
        privacyLevelOptions
        code
        message
        maxVideoPostDurationSec
      }
    }
    categories {
      id
      name
      colorIndex
    }
    user {
      id
      devices {
        id
      }
      onboardingProgress {
        id
        addAccount
        addContent
        addTimeslots
        importContent
        dismissedAt
      }
    }
    company {
      id
      queueStatus
      timeZone
      defaultLinkShortenerDomain {
        id
        name
      }
    }
  }
`;
