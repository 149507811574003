/* @flow */
import * as React from "react";
import createMentionPlugin from "@draft-js-plugins/mention";
import classNames from "classnames";

import Icon from "icons/Icon";
import type { ComposerMention } from "types";
import childrenWithoutAtSymbol from "./childrenWithoutAtSymbol";
import styles from "./index.css";

type Props = {
  mention: ComposerMention,
  children: React.Node
};

export const MentionComponent = ({ mention, children }: Props) => {
  const provider =
    mention &&
    mention.provider &&
    mention.provider.toUpperCase().replace(" ", "_");

  if (!mention || !provider) {
    return <span>{children}</span>;
  }

  const platform = provider.split("_")[0].toLowerCase();

  return (
    <span className={styles.mention}>
      <span
        className={classNames(styles[platform], styles.socialIconContainer)}
      >
        <Icon type={platform} className={styles.socialIcon} />
      </span>
      {children}
    </span>
  );
};

export const makeMentionPlugin = () => {
  //TODO review mention regexp
  return createMentionPlugin({
    entityMutability: "IMMUTABLE",
    mentionComponent: MentionComponent
  });
  const mentionRegExp = /@[a-zA-Z0-9._-]+/g;
  return createMentionPlugin({
    mentionPrefix: "@",
    mentionRegExp: mentionRegExp,
    entityMutability: "IMMUTABLE",
    mentionComponent: MentionComponent
  });
}