/* @flow */
import { gql } from "@apollo/client";

export default gql`
  query getAccountCancellationData {
    user {
      id
      email
      onboardingProgress {
        id
        addAccount
        viewCategories
        addContent
        addTimeslots
        importContent
        dismissedAt
      }
      company {
        totalApprovedContents
        totalPosts
        formattedCreatedAt
        queueStatus
      }
    }
  }
`;
