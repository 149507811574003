/* @flow */
import { gql } from "@apollo/client";

export default gql`
  mutation updateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      errors
    }
  }
`;
