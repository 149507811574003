/* @flow */
import { gql } from "@apollo/client";

export default gql`
  query getLibraryStatus {
    # Add id to the user and company types to avoid an Apollo caching conflict with
    # getContents query.
    #
    # @see https://stackoverflow.com/q/44403930/1858091
    user {
      id
      company {
        id
        libraryStatus
      }
    }
  }
`;
