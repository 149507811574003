import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements, useElements, useStripe } from "@stripe/react-stripe-js";
import StripeCardElement from "./StripeCardElement";
import { ValidationErrorList } from "layout";
import styles from "./index.css";
const el = document.querySelector('meta[name="stripe-key"]');
const TEST_STRIPE_KEY = "pk_test_Y8GjmP8MbnqSFBdpXeVlfTvH";
const stripePromise = loadStripe((el && el.getAttribute("content")) || TEST_STRIPE_KEY );

type Props = {
  errors: string[],
  onComplete: () => void,
  onIncomplete: () => void
};

const PaymentMethod = ({ errors, onComplete, onIncomplete, setToken }: Props) => {
  const getToken = async (stripe, elements) => {
    if (!elements) {
      throw new Error("Stripe elements not available");
    }

    const cardElement = elements.getElement('card');
    if (!cardElement) {
      throw new Error("Expected CardElement to be present");
    }

    try {
      const result = await stripe.createToken(cardElement);
      return result.token;
    } catch (error) {
      console.error("Error getting token: ", error);
      throw error;
    }
  };

  return (
    <Elements stripe={stripePromise}>
      <PaymentMethodContent
        errors={errors}
        setToken={setToken}
        onComplete={onComplete}
        onIncomplete={onIncomplete}
        getToken={getToken}
      />
    </Elements>
  );
};

const PaymentMethodContent = ({ errors, onComplete, onIncomplete, getToken, setToken }) => {
  const stripe = useStripe();
  const elements = useElements();
  return (
    <div>
      <StripeCardElement
      getToken={() => getToken(stripe, elements)}
      setToken={setToken}
        onComplete={onComplete}
        onIncomplete={onIncomplete}
      />
      <ValidationErrorList
        className={styles.formErrors}
        errors={errors.map(err => ({ message: err }))}
      />
    </div>
  );
};

export default PaymentMethod;
