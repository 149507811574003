// @flow
import React from "react";
import { featureFlag } from "util";
import tooltipper from "behavioral/tooltipper";
import Icon from "icons/Icon";
import VideoAttachmentIcon from "components/common/images/video-attachment-icon.svg";
import VideoUploader from "./VideoUploader";
import { VIDEO_REQUIREMENTS_BY_PLATFORM } from "constants";
import styles from "./index.css";

import type {
  Platform,
  Provider,
  loadContent_content_variations as VariationData
} from "graphql-types/loadContent";

const videoExistsReason =
  "Only one video can be attached to a variation. Please remove the current video to add another.";

const imageExistsReason =
  "Variations can't have both video and image attachments. Please remove any images to add a video.";

const unsupportedPlatformNames = featureFlag("pinterest")
  ? "LinkedIn and Pinterest"
  : "LinkedIn";

const unsupportedPlatformReason = `Variations with videos are currently unsupported on ${unsupportedPlatformNames}. Please remove all
${unsupportedPlatformNames} accounts from the content if you'd like to add a video.`;

const uploadInProgressReason = "An upload is already in progress.";

// eslint-disable-next-line max-len
const linkPreviewEnabledReason =
  "Variations can't have both video and link preview attachments. Please remove link preview attachment to add a video.";

const igBusinessMaxAttachmentsReason =
  "Instagram Business accounts support only 10 attachments on a variation (images and videos).";

function getDisabledReason(
  variation,
  platforms,
  uploadingMedia,
  linkAttached,
  selectedProviders
) {
  const { images, videos } = variation;

  const unsupportedPlatforms = platforms.filter(
    p => VIDEO_REQUIREMENTS_BY_PLATFORM[p].SUPPORTS_VIDEO === false
  );

  if (!selectedProviders?.length) {
    return "Please select a provider before adding a video.";
  } else if (
    selectedProviders?.length === 1 &&
    selectedProviders[0] === "INSTAGRAM_BUSINESS" &&
    images.length + videos.length >= 10
  ) {
    return igBusinessMaxAttachmentsReason;
  } else if (images.length > 0) {
    return imageExistsReason;
  } else if (videos.length > 0) {
    return videoExistsReason;
  } else if (unsupportedPlatforms.length) {
    return unsupportedPlatformReason;
  } else if (uploadingMedia) {
    return uploadInProgressReason;
  }

  if (linkAttached) {
    return linkPreviewEnabledReason;
  }

  return null;
}

type Props = {
  content?: object,
  variation: VariationData,
  selectedPlatforms: Platform[],
  selectedProviders: Provider[],
  uploadingMedia: boolean,
  linkAttached: boolean,
  onStart: (variationClientId: string, file: File) => void,
  onProgress: (
    variationClientId: string,
    progress: ?number,
    status: string,
    file: File
  ) => void,
  onUploadError: (
    variationClientId: string,
    message: string,
    file: File
  ) => void,
  onValidationError: (
    variationClientId: string,
    errors: string[],
    file: File
  ) => void,
  onFinish: (variationClientId: string, url: string, file: File) => void,
  instaReels: boolean
};

export const TooltipBody = ({
  variation,
  selectedPlatforms,
  uploadingMedia,
  linkAttached,
  selectedProviders
}: Props) => {
  const disabledReason = getDisabledReason(
    variation,
    selectedPlatforms,
    uploadingMedia,
    linkAttached,
    selectedProviders
  );
  if (disabledReason) {
    return <p>{disabledReason}</p>;
  }

  return (
    <div className={styles.tooltiperBody}>
      <div className={styles.tooltiperBodyLeft}>
        <strong>Twitter</strong>
        <ul>
          <li>MP4 file extensions</li>
          <li>512 MB file size</li>
          <li>Max dimensions of 4096x4096</li>
          <li>Max length 2 min and 20 sec</li>
        </ul>
        <strong>Facebook</strong>
        <ul>
          <li>MP4 and MOV file extensions</li>
          <li>512 MB file size</li>
          <li>Max dimensions of 3840x2160</li>
          <li>No max length</li>
        </ul>
        <strong>TikTok</strong>
        <ul>
          <li>MP4 or WebM file extensions</li>
          <li>Max size 50 MB</li>
          <li>Min resolution of 540p</li>
          <li>Video length: min 3 sec, max: 60 sec</li>
        </ul>
        <strong>Instagram Direct</strong>
        <ul>
          <li>MP4 and MOV file extensions</li>
          <li>100 MB file size</li>
          <li>Max length 60 sec</li>
        </ul>
      </div>

      <div className={styles.tooltiperBodyRight}>
        <strong>Instagram Mobile</strong>
        <ul>
          <li>MP4 and MOV file extensions</li>
          <li>512 MB file size</li>
          <li>Max length 60 sec</li>
        </ul>
        {featureFlag("pinterest") && (
          <>
            <strong>Pinterest</strong>
            <ul>
              <li>Only Pinterest business accounts may publish videos</li>
              <li>MP4 and MOV file extensions</li>
              <li>2 GB file size</li>
              <li>Max length 15 minutes</li>
            </ul>
          </>
        )}
        <strong>Linkedin</strong>
        <ul>
          <li>MP4 file extensions</li>
          <li>200 MB file size</li>
          <li>Max dimensions of 4096x4096</li>
          <li>Min length 4 sec</li>
          <li>Max length 15 min</li>
        </ul>
      </div>
    </div>
  );
};

export const Subtitle = () => (
  <span>
    <br />
    (Supported by <Icon type="facebook-square" /> <Icon type="twitter-square" />{" "}
    <Icon type="instagram" /> <Icon type="pinterest" />)
  </span>
);

export const VideoAttachmentButton = ({
  content,
  variation,
  selectedPlatforms,
  selectedProviders,
  uploadingMedia,
  linkAttached,
  onStart,
  onFinish,
  onProgress,
  onUploadError,
  onValidationError,
  instaReels
}: Props) => {
  const { clientId, images, videos } = variation;
  const inputId = `videoUpload-${clientId}`;
  const disabledReason = getDisabledReason(
    variation,
    selectedPlatforms,
    uploadingMedia,
    linkAttached,
    selectedProviders
  );
  const disabled = !!disabledReason;

  return (
    <>
      <label className={styles.root} htmlFor={disabled ? null : inputId}>
        <img
          src={VideoAttachmentIcon}
          disabled={disabled}
          alt="Add video"
          role="button"
          className={styles.icon}
        />
      </label>
      <VideoUploader
        content={content}
        inputId={inputId}
        selectedProviders={selectedProviders}
        onStart={onStart.bind(null, clientId)}
        onFinish={onFinish.bind(null, clientId)}
        onProgress={onProgress.bind(null, clientId)}
        onUploadError={onUploadError.bind(null, clientId)}
        onValidationError={onValidationError.bind(null, clientId)}
        onVideoAnalysisComplete={() => console.log("onVideoAnalysisComplete")}
        existingImageCount={images.length}
        existingVideoCount={videos.length}
        instaReels={instaReels}
      />
    </>
  );
};

export default tooltipper(TooltipBody, {
  title: "Add video",
  maxWidth: 514,
  Subtitle
})(VideoAttachmentButton);
