/* @flow */
import { gql } from "@apollo/client";

export default gql`
  query getApiKey {
    user {
      id
      apiKey
    }
  }
`;
