/* @flow */
import { gql } from "@apollo/client";

export default gql`
  query getOnboardingProgress {
    user {
      id
      onboardingProgress {
        id
        addAccount
        viewCategories
        addContent
        addTimeslots
        importContent
        dismissedAt
      }
    }
  }
`;
