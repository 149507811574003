/* @flow */
import React from "react";
import DatePicker from "react-datepicker";
import moment from "moment";
import { isMobile } from "react-device-detect";

import SVGCalendar from "components/common/images/calendar.svg";
import SVGArrowRight from "components/common/images/arrow-right.svg";
import SVGCaretDown from "components/common/images/caret-down.svg";

import type { IProps } from "./types.js";
import { Wrapper, CustomInput, StartDate, EndDate } from "./styles";

const DateRange = (props: IProps) => {
  const {
    startDate,
    endDate,
    handleStartDate,
    handleEndDate,
    startDateProps,
    endDateProps,
    minDate,
    maxDate
  } = props;
  const fixedStartDate =
    typeof startDate === "string"
      ? moment(startDate).clone()
      : startDate
      ? moment(startDate)
      : undefined;

  const fixedEndDate =
    typeof endDate === "string"
      ? moment(endDate).clone()
      : endDate
      ? moment(endDate)
      : undefined;

  return (
    <Wrapper>
      <img src={SVGCalendar} alt="calendar icon" className="calendarIcon" />

      <StartDate>
        <DatePicker
          customInput={
            <CustomInput>{moment(startDate).format("MMM D, YYYY")}</CustomInput>
          }
          selected={fixedStartDate.toDate()}
          maxDate={fixedEndDate ? fixedEndDate.clone() : undefined}
          minDate={minDate}
          onChange={handleStartDate}
          title="Click to change start date"
          {...startDateProps}
        />
        <img src={SVGCaretDown} alt="dropdown icon" className="dropdownIcon" />
      </StartDate>

      <img src={SVGArrowRight} alt="arrow icon" className="arrowIcon" />

      <EndDate>
        <DatePicker
          customInput={
            <CustomInput>{moment(endDate).format("MMM D, YYYY")}</CustomInput>
          }
          selected={fixedEndDate.toDate()}
          onChange={handleEndDate}
          minDate={
            fixedStartDate && minDate && minDate.isBefore(fixedStartDate)
              ? fixedStartDate.clone()
              : minDate
              ? minDate
              : fixedStartDate
              ? fixedStartDate.clone()
              : undefined
          }
          maxDate={maxDate ? maxDate : moment()}
          title="Click to change end date"
          popperPlacement={isMobile ? "right" : "left"}
          {...endDateProps}
        />
        <img src={SVGCaretDown} alt="dropdown icon" className="dropdownIcon" />
      </EndDate>
    </Wrapper>
  );
};

export default DateRange;
