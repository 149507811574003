/* @flow */
import React from "react";
import { Tooltip as ReactTooltip } from 'react-tooltip';

import Icon from "icons/Icon";

import SVGCheck from "./check.svg";
import styles from "./index.css";
import type { Props } from "./types.js";
import { Wrapper, Header, Number, Title, Content } from "./styles";

import NextButton from "./NextButton";

const ComposerStep = (props: Props) => {
  const {
    number,
    title,
    subtitle,
    children,
    expanded,
    toggleStep,
    completed,
    nextButton,
    handleNextStep
  } = props;

  return (
    <>
      <Wrapper>
        <Header onClick={() => toggleStep(number)}>
          <div className="left">
            {completed ? (
              <img src={SVGCheck} alt="completed" />
            ) : (
              <Number>{number}</Number>
            )}
            <Title>
              {title + (subtitle ? ": " : "")}
              {subtitle && <span>{subtitle}</span>}
            </Title>
          </div>
          <Icon
            className={styles.icon}
            type={expanded ? "caret-up" : "caret-down"}
            data-for="step-tooltip"
            data-tip="Toggle visibility"
          />
        </Header>
        <Content className={!expanded ? "hidden" : ""}>
          {children}
          {nextButton !== false && handleNextStep ? (
            <NextButton handleNextStep={handleNextStep} stepNumber={number} />
          ) : null}
        </Content>
      </Wrapper>

      <ReactTooltip effect="solid" place="left" id="step-tooltip" />
    </>
  );
};

export default ComposerStep;
