/* @flow */
import { gql } from "@apollo/client";

export default gql`
  mutation createRssFeed($input: CreateRssFeedInput!) {
    createRssFeed(input: $input) {
      rssFeed {
        id
      }
      errors {
        field
        messages
      }
    }
  }
`;
