/* @flow */
import { useEffect, useState } from "react";
import moment from "moment";

import { featureFlag } from "util";

import type { getAccounts_accounts } from "../../graphql/types/getAccounts";

import { MIN_START_DATE, MAX_RANGE_IN_DAYS } from "./utils";
import type { IFilters } from "./types.js";

const useDashboard = () => {
  const [lastAccountId, setLastAccountId] = useState(undefined);
  const [selectedAccount, setSelectedAccount] = useState(undefined);
  const [minDate, setMinDate] = useState(MIN_START_DATE);
  const [maxDate, setMaxDate] = useState(moment());

  const [lastFilters, setLastFilters] = useState(undefined);
  const [filters, setFilters] = useState({
    startDate: moment().subtract(1, "week"),
    endDate: moment()
  });

  const updateFilters = (newFilters: IFilters) => {
    setFilters(newFilters);
  };

  const selectAccount = (account?: getAccounts_accounts) => {
    if (!featureFlag("dashboard_v2")) return;
    setSelectedAccount(account);
  };

  useEffect(() => {
    if (
      (filters && filters.startDate !== lastFilters?.startDate) ||
      (filters && filters.endDate !== lastFilters?.endDate) ||
      selectedAccount?.id !== lastAccountId
    ) {
      let newMinStartDate = moment(filters.endDate)
        .clone()
        .subtract(MAX_RANGE_IN_DAYS, "days");
      let newMaxEndDate = moment(filters.endDate).clone();

      if (selectedAccount?.id) {
        // verify if newMinStartDate is before MIN_START_DATE
        if (newMinStartDate.isBefore(MIN_START_DATE)) {
          newMinStartDate = MIN_START_DATE;
        }

        // if startDate is before MIN_START_DATE, set startDate to MIN_START_DATE
        if (filters.startDate.isBefore(newMinStartDate)) {
          setFilters({
            ...filters,
            startDate: newMinStartDate
          });
        }

        // update max range with MAX_RANGE_IN_DAYS
        if (
          filters.endDate.diff(filters.startDate, "days") > MAX_RANGE_IN_DAYS
        ) {
          newMaxEndDate = moment(filters.startDate)
            .clone()
            .add(MAX_RANGE_IN_DAYS, "days");
          setFilters({
            ...filters,
            endDate: newMaxEndDate
          });
        }

        // if newMinStartDate is before the MIN_START_DATE, set the minDate to the MIN_START_DATE
        if (newMinStartDate.isBefore(MIN_START_DATE)) {
          setMinDate(MIN_START_DATE);
          setMaxDate(MIN_START_DATE.clone().add(MAX_RANGE_IN_DAYS, "days"));
        } else {
          setMinDate(newMinStartDate);
          setMaxDate(newMaxEndDate);
        }
      } else {
        setMinDate(undefined);
        setMaxDate(undefined);
      }

      setLastAccountId(selectedAccount?.id);
      setLastFilters(filters);
    }
  }, [selectedAccount, filters, lastFilters, lastAccountId]);

  return {
    filters,
    updateFilters,
    selectedAccount,
    selectAccount,
    minDate,
    maxDate
  };
};

export default useDashboard;
