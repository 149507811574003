/* @flow */
import { gql } from "@apollo/client";

export default gql`
  query loadContent($contentId: ID!) {
    content(id: $contentId) {
      id
      status
      sendAt
      useOnce
      expiresAt
      useShortLinks
      totalPostCount
      twitterPostedVariationsCount
      sendMobileReminder
      instaReels
      linkedinCarousel
      accountRelationships {
        id
        account {
          id
          name
          description
          nickname
          image
          provider
          providerDisplayName
          platform
          pinterestBoards {
            id
            name
          }
          linkedinAdAccounts {
            id
            name
          }
          tiktokMeta{
              duetDisabled
              maxVideoPostDurationSec
              stitchDisabled
              commentDisabled
              privacyLevelOptions
              maxVideoPostDurationSec
          }
        }
        pinterestBoards {
          id
          name
        }
        linkedinAdAccounts {
          id
          name
        }
      }
      category {
        id
        name
        colorIndex
      }
      variations {
        id
        accountsData {
          id
          text
          accountId
        }
        imagesTextVariations {
          id
          text
          imageId
          variationId
        }
        clientId @client
        text
        fbText
        tiktokMeta{
          privacyStatus
          commercialType
          showCommercialContent
          commentDisabled
          duetDisabled
          stitchDisabled
          maxVideoPostDurationSec
        }
        link
        origin
        rawRichTextEntityMap
        pinterestTitle
        pinterestDestinationLink
        pageScrape {
          id
          url
          status
          title
          description
          images {
            id
            src
            alt
            size
            type
            width
            height
            sha256
          }
        }
        images {
          id
          url
          fileSize
          format
          width
          height
          clientProvidedSha256
        }
        videos {
          id
          url
          fileSize
          formats
          width
          height
          seconds
          clientProvidedSha256
        }
        lastPostedTo {
          twitter {
            sentAt
            account {
              id
              name
              nickname
              providerDisplayName
              platform
              provider
              image
            }
          }
        }
      }
      lastPostedTo {
        twitter {
          sentAt
          account {
            id
            name
            nickname
            providerDisplayName
            platform
            provider
            image
          }
        }
      }
    }
  }
`;
