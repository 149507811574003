// @flow
import React, { useState } from "react";
import { CardElement, useStripe, useElements, ElementsConsumer } from "@stripe/react-stripe-js";
import colors from "colors";
import styles from "./index.css";

const STRIPE_ELEMENT_STYLE = {
  base: {
    fontFamily: '"Open Sans", sans-serif',
    fontSize: "15px",
    fontWeight: 300,
    "::placeholder": {
      color: "rgb(122,122,122)"
    }
  },
  invalid: {
    iconColor: colors.coral600.toString(),
    color: colors.coral600.toString()
  }
};

type Props = {
  onComplete: () => void,
  onIncomplete: () => void,
  clientSecret: string, // New: clientSecret provided by the backend
};

const StripeCardElement = ({ onComplete, onIncomplete, clientSecret, setToken, getToken }: Props) => {
  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState(null);
  const [complete, setComplete] = useState<boolean>(false);
  const [isProcessing, setIsProcessing] = useState<boolean>(false); // New: Used to prevent double submission

  const handleChange = async (evt: Object) => {
    if (evt.error) {
      setErrorMessage(evt.error.message);
    } else if (errorMessage) {
      setErrorMessage(null);
    }

    if (evt.complete !== complete) {
      setComplete(evt.complete);
      if(evt.complete){
        
        await getToken().then(x => {
          setToken(x)
        })
      }
      evt.complete ? onComplete() : onIncomplete();
    }
  };

  const handlePayment = async () => {
    if (!stripe || !elements) return null; // Ensure Stripe and Elements are loaded

    if (!clientSecret) {
      setErrorMessage("No client secret provided.");
      return;
    }

    setIsProcessing(true);
    const cardElement = elements.getElement(CardElement);
    try {
      const { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: cardElement,
          billing_details: {
            name: 'Customer Name', // Update to get dynamic customer info if needed
          },
        },
      });

      if (error) {
        setErrorMessage(error.message);
      } else if (paymentIntent.status === 'succeeded') {
        onComplete();
      } else {
        onIncomplete();
      }
    } catch (e) {
      setErrorMessage(e.message);
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <div>
      <div
        className={styles.cardElementContainer}
        id="stripe-iframe-container"
      >
        <CardElement
          options={{ style: STRIPE_ELEMENT_STYLE }}
          onChange={handleChange}
        />
      </div>
      <div className={styles.errorMessage}>{errorMessage}</div>
    </div>
  );
};

const WrappedStripeCardElement = (props) => (
  <ElementsConsumer>
    {({ stripe, elements }) => (
      <StripeCardElement stripe={stripe} elements={elements} {...props} />
    )}
  </ElementsConsumer>
);

export default WrappedStripeCardElement;
