/* @flow */
import { gql } from "@apollo/client";

export default gql`
  mutation createScheduleV2($input: CreateScheduleInputTypeV2!) {
    createScheduleV2(input: $input) {
      schedules {
        id
      }
      company {
        id
        scheduleLimitReached
      }
      errors
    }
  }
`;
