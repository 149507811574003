/* @flow */
import { gql } from "@apollo/client";

export default gql`
  mutation validateCoupon($couponCode: String!, $stripePlanId: String!) {
    validateCoupon(couponCode: $couponCode, stripePlanId: $stripePlanId) {
      coupon {
        id
        description
      }
      errors
    }
  }
`;
