/* @flow */
import { gql } from "@apollo/client";

export default gql`
  mutation t($message: String!, $attributes: String!) {
    t(message: $message, attributes: $attributes) {
      success
    }
  }
`;
