import { gql } from "@apollo/client";

export const ALL_CATEGORY_FIELDS_FRAGMENT = gql`
  fragment allCategoryFields on Category {
    id
    name
    colorIndex
    useForRandom
    editable
  }
`;
