/* @flow */
import { gql } from "@apollo/client";

export default gql`
  query dashboardAccount($startDate: ISO8601Date!, $endDate: ISO8601Date!) {
    dashboardAccount(input: { startDate: $startDate, endDate: $endDate }) {
      account {
        id
        provider
        providerDisplayName
        name
        image
      }
      impressionCount
      sentCount
      totalClicks
    }
  }
`;
