import { gql } from "@apollo/client";

export const GET_INVITATION = gql`
  query retrieveInvitation($token: String!) {
    invitation(token: $token) {
      id
      email
    }
  }
`;
