/* @flow */
import { gql } from "@apollo/client";

export default gql`
  query getReports {
    reports {
      data {
        endDate
      }
    }
    user {
      id
      onboardingProgress {
        id
        addAccount
        viewCategories
        addContent
        addTimeslots
        importContent
        dismissedAt
      }
    }
  }
`;
