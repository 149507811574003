/* @flow */
import { gql } from "@apollo/client";

export default gql`
  mutation createInvitation($input: CreateTeamMemberInput!) {
    createTeamMember(input: $input) {
      errors {
        field
        messages
      }
    }
  }
`;
