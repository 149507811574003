/* @flow */
import { gql } from "@apollo/client";

export default gql`
  mutation createImage($input: CreateImageInput!) {
    createImage(input: $input) {
      image {
        id
        url
        fileSize
        format
        width
        height
        clientProvidedSha256
      }
      errors
    }
  }
`;
