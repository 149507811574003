/* @flow */
import { gql } from "@apollo/client";

export default gql`
  query getUpcomingInvoice($stripePlanId: String!, $couponCode: String) {
    upcomingInvoice(stripePlanId: $stripePlanId, couponCode: $couponCode) {
      id
      due
    }
  }
`;
