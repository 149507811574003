/* @flow */
import { gql } from "@apollo/client";

export default gql`
  mutation updateFeatureFlag($input: Boolean!) {
    updateFeatureFlag(input: $input) {
      errors
    }
  }
`;
