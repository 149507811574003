/* @flow */
import { gql } from "@apollo/client";

export default gql`
  mutation updateScheduleV2($input: UpdateScheduleInputTypeV2!) {
    updateScheduleV2(input: $input) {
      schedule {
        id
      }
      errors
    }
  }
`;
