// @flow
import React, { Component, type Node } from "react";
import { getMomentInTimeZone } from "util";
import DateInput, { type popperPlacement } from "forms/DateInput";
import TimeField from "./TimeField";
import moment from "moment";
import Link from "links/Link";
import Caption from "typography/Caption";
import styles from "./index.css";

/*
  NOTE - When editing content the component allows the scheduled time to be maintained
  even if its in the past. The current implementation of this is working on the assumption
  that the component will be mounted once per content being edited.
*/

type Props = {
  captionText: string,
  captionLink?: {
    text: string,
    href: string
  },
  timeZone: string,
  value: ?string,
  defaultValue?: string,
  disabled?: boolean,
  notice?: Node,
  popperPlacement?: popperPlacement,
  enableResetLink?: boolean,
  onChange: (expiresAt: ?string) => void
};

type State = {
  originalValue: ?string
};

export default class DateTimeField extends Component<Props, State> {
  state = { originalValue: this.props.value };

  handleChange: (value: Object) => void = value => {
    this.props.onChange(value ? moment(value).format() : this.props.value);
  };

  handleReset = () => {
    this.handleChange(
      getMomentInTimeZone(this.state.originalValue, this.props.timeZone)
    );
  };

  render() {
    const { originalValue } = this.state;
    const {
      value,
      defaultValue,
      disabled,
      timeZone,
      popperPlacement,
      enableResetLink = false
    } = this.props;
    const valueMoment = value ? getMomentInTimeZone(value, timeZone) : null;
    const defaultValueMoment = getMomentInTimeZone(defaultValue, timeZone);
    const showReset =
      originalValue &&
      enableResetLink &&
      !getMomentInTimeZone(originalValue, timeZone).isSame(valueMoment);

    const areInputsDisabled = disabled || !value;

    return (
      <div className={styles.root}>
        {this.props.notice}
        <Caption className={styles.helpText} helpText>
          {this.props.captionText}
          {this.props.captionLink && (
            <span>
              {" "}
              (
              <a
                href={this.props.captionLink.href}
                tabIndex="-1"
                target="_blank"
              >
                {this.props.captionLink.text}
              </a>
              )
            </span>
          )}
        </Caption>
        <div className={styles.inputs}>
          <TimeField
            className={styles.timeInput}
            value={valueMoment || defaultValueMoment}
            disabled={areInputsDisabled}
            onChange={this.handleChange}
          />
          <DateInput
            className={styles.dateInput}
            value={(valueMoment && valueMoment.toDate()) || defaultValueMoment.toDate()}
            disabled={areInputsDisabled}
            minValue={getMomentInTimeZone(Date.now(), timeZone).toDate()}
            popperPlacement={popperPlacement}
            onChange={this.handleChange}
          />
        </div>
        {showReset && (
          <Link className={styles.resetLink} onClick={this.handleReset}>
            Reset to orignally scheduled time
          </Link>
        )}
      </div>
    );
  }
}
