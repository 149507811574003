/* @flow */
import { gql } from "@apollo/client";

export default gql`
  mutation instagramSendMessage(
    $accountId: Int!
    $recipientId: String!
    $message: String!
  ) {
    instagramSendMessage(
      accountId: $accountId
      recipientId: $recipientId
      message: $message
    ) {
      messageId
      error
    }
  }
`;
