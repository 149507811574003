/* @flow */
import { gql } from "@apollo/client";

export default gql`
  mutation createSchedule($input: CreateScheduleInput!) {
    createSchedule(input: $input) {
      schedules {
        id
      }
      company {
        id
        scheduleLimitReached
      }
      errors
    }
  }
`;
