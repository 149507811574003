/* @flow */
import { gql } from "@apollo/client";

export default gql`
  mutation generateContent($input: GenerateAiContentInput) {
    generateContent(input: $input) {
      conversation {
        id
        prompt
        messages {
          id
          message
        }
      }
      errors
      credits
    }
  }
`;
