/* @flow */
import { gql } from "@apollo/client";

export default gql`
  query getFacebookConversationThread(
    $accountId: Int!
    $threadId: String!
    $cursor: String!
  ) {
    fetchFacebookConversationThreads(
      accountId: $accountId
      threadId: $threadId
      cursor: $cursor
    ) {
      cursor
      error
      threads {
        attachments {
          name
          fileUrl
          previewUrl
        }
        createdTime
        from {
          id
          name
        }
        id
        message
        shares {
          link
        }
        to {
          id
          name
        }
      }
    }
  }
`;
