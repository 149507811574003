/* @flow */
import { gql } from "@apollo/client";

export default gql`
  query getAccounts(
    $onlyPostable: Boolean = false
    $withInboxSupport: Boolean = false
    $withDashboardSupport: Boolean = false
  ) {
    accounts(
      onlyPostable: $onlyPostable
      withInboxSupport: $withInboxSupport
      withDashboardSupport: $withDashboardSupport
    ) {
      id
      uid
      name
      image
      nickname
      parent {
        id
        uid
      }
      parentNickname
      provider
      providerDisplayName
      platform
      description
      canRefresh
      canDelete
      isOk
      pinterestBoards {
        id
        name
      }
      linkedinAdAccounts {
        id
        name
      }
    }
  }
`;
