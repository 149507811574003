/* @flow */
import { gql } from "@apollo/client";

export default gql`
  query getVariationSuggestions($urls: [String!]!) {
    variationSuggestions(urls: $urls) {
      url
      text
    }
  }
`;
