// @flow
import React, { Component } from "react";
import classNames from "classnames";
import createEmojiPlugin from "@draft-js-plugins/emoji";

import Expander from "layout/Expander";
import Label from "typography/Label";
import AccountProviderIcon from "icons/AccountProviderIcon";
import ErrorMessageList from "layout/ErrorMessageList";
import ButtonRow from "../TextField/ButtonRow";
import BlurredInput from "../TextField/BlurredInput";
import PostEditor from "../TextField/PostEditor";
import styles from "./index.css";

import type {
  loadContent_content_variations as VariationData,
  Provider
} from "graphql-types/loadContent";
import type { createMentionTagMap_createMentionTagMap_mentionTagMap as MentionTagMapData } from "graphql-types/createMentionTagMap";

const emojiPlugin = createEmojiPlugin({ useNativeArt: true });

type Props = {
  selectedProviders: Provider[],
  mentionSuggestions: MentionTagMapData[],
  isWidget: boolean,
  visible: boolean,
  focused: boolean,
  fieldFocused: boolean,
  hasErrors: boolean,
  hasWarnings: boolean,
  className: string,
  value: VariationData,
  fieldName: string,
  errors: string[],
  onClearMentionSuggestions: () => void,
  onAddVariation: (evt: Event) => void,
  onAddMention: (mentionTagMap: Object) => void,
  onMentionSuggestionWarning: (value: string) => void,
  onChange: (fbText: string) => void,
  onEntityMapChange: (entityMap: Object) => void,
  onSearchSocialMedia: (provider: Provider, query: string) => void,
  onFocus: () => void,
  onBlur: () => void
};

export class FbTextField extends Component<Props> {
  componentDidUpdate(prevProps: Props) {
    const variationReceivedFocus = !prevProps.focused && this.props.focused;
    const fieldReceivedFocus =
      !prevProps.fieldFocused && this.props.fieldFocused;

    if (
      (variationReceivedFocus || fieldReceivedFocus) &&
      this.props.fieldFocused &&
      !!this.input
    ) {
      // HACK: If the <PostEditor /> is given focus immediately then mentions don't work
      // (╯°□°）╯︵ ┻━┻
      setTimeout(this.input.focus, 50);
    }
  }

  handleLabelFocus = (evt: Event) => {
    evt.stopPropagation();
    this.props.onFocus();
  };

  input: any;

  setInput = (node: any) => {
    this.input = node;
  };

  render() {
    const {
      selectedProviders,
      mentionSuggestions,
      onClearMentionSuggestions,
      onMentionSuggestionWarning,
      onEntityMapChange,
      onSearchSocialMedia,
      onAddMention,
      visible,
      focused,
      hasErrors,
      hasWarnings,
      isWidget,
      className,
      value,
      fieldName,
      errors,
      onChange,
      onFocus,
      onBlur
    } = this.props;

    const classes = classNames(className, {
      [styles.hasWarnings]: visible && !hasErrors && hasWarnings,
      [styles.hasErrors]: visible && hasErrors
    });

    return (
      <Expander expanded={visible} className={classes}>
        <Label
          small
          className={styles.label}
          htmlFor={fieldName}
          onClick={this.handleLabelFocus}
        >
          Facebook text
          <AccountProviderIcon small provider="facebook" />
        </Label>
        {visible && focused && (
          <div>
            <PostEditor
              value={value.fbText || ""}
              inputRef={node => this.setInput(node)}
              rawRichTextEntityMap={value.rawRichTextEntityMap}
              selectedProviders={selectedProviders}
              emojiPlugin={emojiPlugin}
              mentionSuggestions={mentionSuggestions}
              onClearMentionSuggestions={onClearMentionSuggestions}
              isWidget={isWidget}
              onChange={onChange}
              onBlur={onBlur}
              onEntityMapChange={onEntityMapChange}
              onSearchSocialMedia={onSearchSocialMedia}
              onMentionSuggestionWarning={onMentionSuggestionWarning}
              onAddMention={onAddMention}
            />
            <ErrorMessageList className={styles.errorList} errors={errors} />
            <ButtonRow
              value={value}
              emojiPlugin={emojiPlugin}
              facebookSelected
            />
          </div>
        )}
        {visible && !focused && (
          <BlurredInput
            value={value.fbText || ""}
            decorateMentions
            rawRichTextEntityMap={value.rawRichTextEntityMap}
            onClick={onFocus}
          />
        )}
      </Expander>
    );
  }
}

export default FbTextField;
