/* @flow */
import { gql } from "@apollo/client";

export default gql`
  query getNewUI {
    getNewUI {
      enabled
    }
  }
`;
