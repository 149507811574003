// @flow
import React, { Component } from "react";
import { debounce, get, omit, pick, trimEnd } from "lodash";
import produce from "immer";
import {
  ContentState,
  EditorState,
  SelectionState,
  Modifier,
  convertFromHTML,
  convertToRaw,
  convertFromRaw
} from "draft-js";
import Editor from "@draft-js-plugins/editor";

import { makeMentionPlugin } from "./MentionPlugin";
import MentionSuggestionsEntry from "./MentionSuggestionsEntry";
import MentionSuggestionsError from "./MentionSuggestionsError";

// $FlowFixMe
import "@draft-js-plugins/mention/lib/plugin.css";
// $FlowFixMe
import "@draft-js-plugins/emoji/lib/plugin.css";

import styles from "./index.css";

import type { RawRichTextEntityMap, AccountSearchResult } from "types";
import type { Provider } from "graphql-types/loadComposer";

const PROVIDERS_WITH_MENTION = ["TWITTER", "FACEBOOK_PAGE"];
const ON_SEARCH_CHANGE_DEBOUNCE_INTERVAL = 300;
const MENTION_SUGGESTIONS_STYLE = {
  width: "275px",
  overflowX: "hidden",
  overflowY: "scroll",
  maxHeight: "450px",
  display: "block"
};

type Props = {
  value: string,
  rawRichTextEntityMap: ?RawRichTextEntityMap,
  emojiPlugin: Object,
  onChange: (text: string) => void,
  onBlur: () => void,
  selectedProviders: Provider[],
  mentionSuggestions: AccountSearchResult[],
  inputRef: any,
  isWidget: boolean,
  onSearchSocialMedia: (provider: Provider, query: string) => void,
  onClearMentionSuggestions: () => void,
  onMentionSuggestionWarning: (value: string) => void,
  onAddMention: (mentionTagMap: Object) => void,
  onEntityMapChange: (entityMap: Object) => void
};

type State = {
  editorState: EditorState,
  mentionPlugin: any,
  handledWidget: boolean,
  opem: boolean
};

const DEFAULT_TEXT =
  "Enter your text here! Add a link and Edgar will help you find images and import suggested variations.";
const DEFAULT_TEXT_WITH_PINTEREST =
  "Enter your text here! For Pinterest, add a description here and a short headline in the Pin title field below.";

export class PostEditor extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const { rawRichTextEntityMap, value } = props;
    const entityMap = get(rawRichTextEntityMap, "entityMap", {});
    const blocks = get(rawRichTextEntityMap, "blocks");
    let editorState;

    if (blocks && Object.keys(entityMap).length) {
      editorState = EditorState.createWithContent(
        convertFromRaw(rawRichTextEntityMap)
      );
    } else if (value && value.trim()) {
      const contentState = this.contentStateFromExistingContentValue(value);
      editorState = EditorState.createWithContent(contentState);
    } else {
      editorState = EditorState.createEmpty();
    }

    const mentionPlugin = makeMentionPlugin();

    this.state = { editorState, mentionPlugin, handledWidget: false, open: false };
  }

  componentWillMount() {
    const editorState = EditorState.moveSelectionToEnd(this.state.editorState);
    this.setState({ editorState });
  }

  componentDidMount() {
    if (this.refs.inputEl) {
      this.refs.inputEl.value = this.props.value;
      this._startValueMonitor();
    }
  }

  componentWillReceiveProps({ value: newValue, isWidget }: Props) {
    // When the value changes restart the value monitor so the value still present in DOM
    // cannot override the new value coming from props
    if (this.props.value !== newValue) {
      this._restartValueMonitor();
    }

    if (
      isWidget &&
      !this.state.handledWidget &&
      this.props.value === "" &&
      newValue.length > 0
    ) {
      const contentState = this.contentStateFromExistingContentValue(newValue);
      let editorState = EditorState.createWithContent(contentState);
      editorState = EditorState.moveSelectionToEnd(editorState);
      this.setState({ editorState, handledWidget: true });
    }
  }

  componentDidUpdate() {
    const { value } = this.props;
    const contentStateText = this.state.editorState
      .getCurrentContent()
      .getPlainText();

    if (value === "" && value !== contentStateText) {
      const contentState = ContentState.createFromText("");
      this.handleOnChange(
        EditorState.push(this.state.editorState, contentState, null)
      );

      if (this.refs.inputEl) {
        this.refs.inputEl.value = value;
      }
    }
  }

  componentWillUnmount() {
    this._clearValueMonitor();
  }
  getRichPlainText(contentState){
    let richText = '';
  
    // Iterate through all the content blocks
    contentState.getBlocksAsArray().forEach((block) => {
      const blockText = block.getText(); // Plain text of the block
      const blockKey = block.getKey();
  
      let blockRichText = '';
      let lastOffset = 0;
  
      // Get the entity ranges (if any)
      block.findEntityRanges(
        (character) => {
          const entityKey = character.getEntity();
          return entityKey !== null; // Check if this character is part of an entity
        },
        (start, end) => {
          // Append text before the entity
          blockRichText += blockText.slice(lastOffset, start);
  
          const entityKey = block.getEntityAt(start);
          const entity = contentState.getEntity(entityKey);
          if (entity.getType() === 'MENTION' || entity.getType() === 'mention') {
            const mentionData = entity.getData(); // Get the mention data
            const mentionText = mentionData.mention.data || blockText.slice(start, end); // Use custom or original text
            blockRichText += `@${mentionText}`;
          } else {
            blockRichText += blockText.slice(start, end); // Append entity text as-is
          }
  
          lastOffset = end; // Update offset
        }
      );
  
      // Append any text after the last entity
      blockRichText += blockText.slice(lastOffset);
      richText += blockRichText + '\n'; // Add a newline after each block
    });
  
    return richText.trim(); // Remove trailing newline
  }
  handleOnChange = (editorState: EditorState) => {
    const lastChangeType = editorState.getLastChangeType();

    if (!lastChangeType) {
      // DraftJS Editor can trigger handleOnChange to update the selection and focus of the
      // cursor without an actual text change. The change type will be `null` if the Editor
      // attempts to update the selection state. Without this, the cursor will jump in the editor.

      if (lastChangeType === null) {
        this.setState({ editorState });
      }

      return;
    }

    const text = this.getRichPlainText(editorState.getCurrentContent());
    this.props.onChange(text);

    if (this.refs.inputEl) {
      this.refs.inputEl.value = text;
    }

    if (!!this.props.rawRichTextEntityMap) {
      const contentState = convertToRaw(editorState.getCurrentContent());
      this.handleOnEntityMapChange(contentState);
    }

    this.setState({
      editorState
    });
  };

  handleOnSearchChange = debounce(({ value }: { value: string }) => {
    if (value.length <= 1) {
      this.setState({open: false});
      return;
    }

    if (this.canAddMention()) {
      this.props.onSearchSocialMedia(this.getDistinctProviders()[0], value);
      this.setState({open: true});
    } else {
      this.props.onMentionSuggestionWarning(value);
    }
  }, ON_SEARCH_CHANGE_DEBOUNCE_INTERVAL);

  handleOnAddMention = (mention: Object) => {
    if (mention.displayName === "edgarInfoEntry") {
      this.props.onClearMentionSuggestions();
      return;
    }

    this.props.onAddMention({
      displayName: mention.name,
      id: mention.id,
      provider: this.getDistinctProviders()[0]
    });

    const contentState = convertToRaw(
      this.state.editorState.getCurrentContent()
    );
    this.handleOnEntityMapChange(contentState);
  };

  handleOnEntityMapChange = (contentState: ContentState) => {
    const newContentState = produce(contentState, draft => {
      Object.keys(draft.entityMap).forEach(key => {
        const data = draft.entityMap[key].data;

        if (!data || !data.mention) {
          draft.entityMap = omit(draft.entityMap, key);
          return;
        }

        const provider = data.mention.provider;
        const name = data.mention.name;
        const id = data.mention.id;

        if (!!provider) {
          data.mention = { provider, name, id };
          draft.entityMap[key].data = data;
          draft.entityMap[key] = pick(draft.entityMap[key], ["data", "type"]);
        } else {
          draft.entityMap = omit(draft.entityMap, key);
        }
      });
    });
    this.props.onEntityMapChange(newContentState);
  };

  _valueMonitor = null;

  _getContentStateWithReplacedText = (
    currentValueChunks: string[],
    inputValueChunks: string[]
  ) => {
    const { editorState } = this.state;
    const blockMap = editorState.getCurrentContent().getBlockMap();
    const selectionsToReplace = [];
    const mismatchedTexts = currentValueChunks
      .map((chunk, i) =>
        chunk === inputValueChunks[i]
          ? {}
          : { oldValue: chunk, newValue: inputValueChunks[i] }
      )
      .filter(x => !!x);

    const findWithRegex = (contentBlock, callback) => {
      mismatchedTexts.forEach(({ oldValue, newValue }) => {
        if (oldValue && newValue) {
          const regex = new RegExp(oldValue);
          const text = contentBlock.getText();
          const matches = regex.exec(text);

          if (matches) {
            const start = matches.index;
            const end = start + matches[0].length;
            callback(start, end, newValue);
          }
        }
      });
    };

    blockMap.forEach(contentBlock =>
      findWithRegex(contentBlock, (start, end, newValue) => {
        const blockKey = contentBlock.getKey();
        const blockSelection = SelectionState.createEmpty(blockKey).merge({
          anchorOffset: start,
          focusOffset: end
        });

        selectionsToReplace.push({ blockSelection, newValue });
      })
    );

    let contentState = editorState.getCurrentContent();

    selectionsToReplace.forEach(({ blockSelection, newValue }) => {
      contentState = Modifier.replaceText(
        contentState,
        blockSelection,
        newValue
      );
    });

    return EditorState.push(
      this.state.editorState,
      contentState,
      "insert-fragment"
    );
  };

  _getContentStateWithInsertedText = (
    currentValue: string,
    inputValue: string
  ) => {
    const editorState = EditorState.moveSelectionToEnd(this.state.editorState);
    let contentState = editorState.getCurrentContent();
    const selectionState = editorState.getSelection();
    const insertedText = trimEnd(inputValue.substr(currentValue.length));
    contentState = Modifier.insertText(
      contentState,
      selectionState,
      insertedText
    );

    return EditorState.push(editorState, contentState, "insert-fragment");
  };

  _startValueMonitor = () => {
    this._valueMonitor = setInterval(() => {
      if (!this.refs.inputEl) {
        return;
      }

      const { value: currentValue } = this.props;
      const { value: inputValue } = this.refs.inputEl;

      if (currentValue === inputValue) {
        return;
      }

      const currentValueChunks = currentValue.trim().split(" ");
      const inputValueChunks = inputValue.trim().split(" ");
      let editorState;

      if (currentValueChunks.length === inputValueChunks.length) {
        editorState = this._getContentStateWithReplacedText(
          currentValueChunks,
          inputValueChunks
        );
      } else if (currentValueChunks.length < inputValueChunks.length) {
        // URL was appended to text by Sniply
        editorState = this._getContentStateWithInsertedText(
          currentValue,
          inputValue
        );
      } else {
        return;
      }

      this.handleOnChange(editorState);
    }, 20);
  };

  _clearValueMonitor = () => {
    if (this._valueMonitor) {
      clearInterval(this._valueMonitor);
    }
  };

  _restartValueMonitor = () => {
    this._clearValueMonitor();
    this._startValueMonitor();
  };

  contentStateFromExistingContentValue(value: string) {
    const valueWithReplacedNewlines = value.replace(/\n/gi, "<br />");
    const blocksFromHTML = convertFromHTML(valueWithReplacedNewlines);
    return ContentState.createFromBlockArray(blocksFromHTML);
  }

  getDistinctProviders = () => [...new Set(this.props.selectedProviders)];

  canAddMention = () => {
    const providers = this.getDistinctProviders();
    return (
      providers.length === 1 && PROVIDERS_WITH_MENTION.includes(providers[0])
    );
  };

  mentionSupportedProvidersSelected = () => {
    const providers = this.getDistinctProviders();
    return (
      providers.length &&
      providers.every(prov => PROVIDERS_WITH_MENTION.includes(prov))
    );
  };

  getMentionSuggestions = () => {
    const providers = this.getDistinctProviders();
    if (providers.includes("FACEBOOK_PAGE")) {
      return [
        { displayName: "edgarInfoEntry", name: "" },
        ...this.props.mentionSuggestions
      ];
    }
    return this.props.mentionSuggestions;
  };

  getMentionProps = () => ({
    suggestions: this.getMentionSuggestions(),
    onSearchChange: this.handleOnSearchChange,
    onAddMention: this.canAddMention()
      ? this.handleOnAddMention
      : this.props.onClearMentionSuggestions,
      open: this.state.open,
    onOpenChange: this.canAddMention() ? () => {
      
    } : this.props.onClearMentionSuggestions
  });

  chooseEntryComponent = () => {
    const Entry = this.canAddMention()
      ? MentionSuggestionsEntry
      : MentionSuggestionsError;
    return (props: { selectedProviders: Provider[] }) => (
      <Entry {...props} selectedProviders={this.props.selectedProviders} />
    );
  };

  pinterestAccountSelected = () =>
    (this.props.selectedProviders ?? []).some(p => p === "PINTEREST");

  render() {
    const { EmojiSuggestions } = this.props.emojiPlugin;
    const { MentionSuggestions } = this.state.mentionPlugin;
    return (
      <div className={styles.editor}>
        <Editor
          stripPastedStyles
          spellCheck
          placeholder={
            this.pinterestAccountSelected()
              ? DEFAULT_TEXT_WITH_PINTEREST
              : DEFAULT_TEXT
          }
          ref={this.props.inputRef}
          editorState={this.state.editorState}
          plugins={[this.state.mentionPlugin, this.props.emojiPlugin]}
          onChange={this.handleOnChange}
          onBlur={this.props.onBlur}
        />
        <div className={styles.emojiSuggestionsContainer}>
          <EmojiSuggestions />
        </div>
        <textarea ref="inputEl" style={{ display: "none" }} id="text" />
        {!!this.mentionSupportedProvidersSelected() && (
          <MentionSuggestions
            entryComponent={this.chooseEntryComponent()}
            {...this.getMentionProps()}
            style={MENTION_SUGGESTIONS_STYLE}
          />
        )}
      </div>
    );
  }
}

export default PostEditor;
