/* @flow */
import { gql } from "@apollo/client";

export default gql`
  mutation rejectAllContent($filter: ContentsFilter!) {
    rejectAllContent(filter: $filter) {
      contents {
        id
        status
      }
      errors
    }
  }
`;
