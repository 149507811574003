/* @flow */
import React, { useCallback } from "react";
import classNames from "classnames";

import Button from "buttons/Button";
import Label from "typography/Label";
import Caption from "typography/Caption";
import Product from "../Product";
import PaymentCycle from "./PaymentCycle";

import styles from "./index.css";

import type { FlashType } from "reducers/flash";
import type {
  getUserSettings_user_company as Company,
  getUserSettings_user_company_subscriptionPlan as SubscriptionPlan
} from "graphql-types/getUserSettings";
import type {
  updateSubscription as UpdateSubscriptionResult,
  updateSubscriptionVariables
} from "graphql-types/updateSubscription";

type Props = {
  company?: ?Company,
  couponCode?: string,
  flash: (type: FlashType, body: string) => void,
  isPlanSelectorOpen?: boolean,
  closePlanSelector?: () => void,
  selectPlan: SubscriptionPlan => void,
  selectedPlan?: ?SubscriptionPlan,
  selectProduct?: (selectedProduct: string) => void,
  selectedProduct?: ?string,
  selectPaymentCycle?: (selectedPaymentCycle: string) => void,
  updateSubscription?: ({
    variables: updateSubscriptionVariables
  }) => Promise<{ data: UpdateSubscriptionResult }>,
  updateSubscriptionLoading?: boolean,
  alignment: "left" | "center"
};

export const PlanSelector = ({
  company,
  couponCode,
  flash,
  isPlanSelectorOpen,
  closePlanSelector,
  selectPlan,
  selectedPlan,
  updateSubscription,
  updateSubscriptionLoading,
  alignment = "left"
}: Props) => {
  const handleOnClickSave = useCallback(async () => {
    const selectedPlanId = selectedPlan?.id;
    const selectedCouponCode = couponCode ?? null;

    if (!updateSubscription || !selectedPlanId) {
      return;
    }

    const response = await updateSubscription({
      variables: {
        planId: selectedPlanId,
        couponCode: selectedCouponCode
      }
    });

    const errors = response?.data?.updateSubscription?.errors || [];
    if (errors.length > 0) {
      flash("error", errors[0]);
    } else if (response?.data?.updateSubscription?.result) {
      closePlanSelector?.();
      window.location = response?.data?.updateSubscription?.result;
    } else {
      flash("notice", "You updated your subscription successfully.");
      closePlanSelector?.();
    }
  }, [updateSubscription, selectedPlan, couponCode, flash, closePlanSelector]);

  // ensure that the monthly plan always comes first
  const allPlans = [...(company?.availableSubscriptionPlans || [])].sort(
    ({ interval }) => (interval === "month" ? -1 : 1)
  );
  const productPlans = allPlans.filter(
    p => selectedPlan?.productName === p.productName
  );

  const handleSelectProduct = product => {
    if (!company) {
      return;
    }

    const newPlan = allPlans.find(plan => plan.productName === product);
    if (!newPlan) {
      return;
    }

    selectPlan(newPlan);
  };

  const handleSelectPaymentCycle = interval => {
    if (!company) {
      return;
    }

    const newPlan = productPlans.find(plan => plan.interval === interval);
    if (!newPlan) {
      return;
    }

    selectPlan(newPlan);
  };

  if (
    !isPlanSelectorOpen ||
    !company ||
    !company.availableSubscriptionPlans ||
    company.availableSubscriptionPlans.length < 1 ||
    !selectedPlan
  ) {
    return null;
  }

  const previousPlans = [
    "eddie",
    "eddie_nt",
    "eddie_annual",
    "eddie_annual_nt",
    "edgar_49s",
    "edgar_49s_nt",
    "edgar_annual_539",
    "edgar_annual_539_nt"
  ];

  const checkPlan = previousPlans.includes(selectedPlan?.stripeId);
  const oldPlan = previousPlans.includes(company?.subscriptionPlan?.stripeId);

  return (
    <>
      <Products
        alignment={alignment}
        currentProduct={company?.subscriptionPlan?.productName}
        selectedProduct={checkPlan ? "" : selectedPlan?.productName}
        selectProduct={handleSelectProduct}
        oldPlan={oldPlan}
        company={company}
      />
      <Label className={styles.subheader}>
        {isPlanSelectorOpen && company.upgradeAvailable
          ? "Choose your payment option"
          : "Payment Cycle"}
      </Label>

      <PaymentCycles
        alignment={alignment}
        plans={productPlans}
        currentPlan={company?.subscriptionPlan}
        selectedPlan={checkPlan ? "" : selectedPlan}
        selectPaymentCycle={handleSelectPaymentCycle}
        company={company}
      />

      <UpgradeMessage
        alignment={alignment}
        subscribed={company.subscribed}
        oldPlan={company.subscriptionPlan}
        newPlan={selectedPlan}
      />

      <div
        className={classNames({
          [styles.centerButtons]: alignment === "center",
          [styles.rightButtons]: alignment === "left"
        })}
      >
        <Button
          className={styles.buttonCancel}
          onClick={closePlanSelector}
          type="secondary"
        >
          Cancel
        </Button>
        <Button
          className={styles.button}
          disabled={
            selectedPlan.stripeId === company.subscriptionPlan?.stripeId ||
            updateSubscriptionLoading
          }
          onClick={handleOnClickSave}
          type="primary"
        >
          Save
        </Button>
      </div>
    </>
  );
};

const UpgradeMessage = ({ subscribed, oldPlan, newPlan, alignment }) => {
  const isLessExpensive = (newPlan.amount || 0) <= (oldPlan?.amount || 0);
  const isIntervalUpgrade =
    oldPlan?.interval === "month" && newPlan.interval === "year";

  if (!subscribed || isLessExpensive) {
    return null;
  }

  if (isIntervalUpgrade) {
    return (
      <Caption
        className={classNames(styles.caption, {
          [styles.centerCaption]: alignment === "center"
        })}
      >
        Clicking the “Save” button will immediately charge your card on file for
        the Annual rate, minus a prorated amount for the time remaining on your
        current month. Your account will automatically renew on this date each
        year. If you are on a free trial, you will not be charged until your
        trial ends.
      </Caption>
    );
  }

  return (
    <Caption
      className={classNames(styles.caption, {
        [styles.centerCaption]: alignment === "center"
      })}
    >
      Clicking the “Save” button will switch your plan immediately, and your
      card will be charged a prorated amount for the difference in plan price
      for the current month. Your billing date will not change. If you are on a
      free trial, you will not be charged until your trial ends.
    </Caption>
  );
};

const Products = ({
  currentProduct,
  oldPlan,
  selectProduct,
  selectedProduct,
  alignment
}) => {
  // the user is on a lite plan, in which case we offer a single product upgrade of 'Edgar'

  if (currentProduct !== "edgar_lite" && oldPlan) {
    // the user is on an 'Edgar' plan
    // we do not allow downgrading from non-lite or grandfathered plans, in which
    //  case we only show a single 'Edgar' product option
    return (
      <Product
        isCurrent
        isSelectable
        isSelected={selectedProduct === "edgar"}
        product="edgar"
        selectProduct={selectProduct}
      />
    );
  }

  return (
    <div
      className={classNames({
        [styles.centerPlans]: alignment === "center",
        [styles.leftPlans]: alignment === "left"
      })}
    >
      <Product
        isCurrent={selectedProduct === "edgar_lite"}
        isSelectable
        isSelected={selectedProduct === "edgar_lite"}
        product="edgar_lite"
        selectProduct={selectProduct}
      />
      <Product
        isCurrent={selectedProduct === "edgar"}
        isSelectable
        isSelected={selectedProduct === "edgar"}
        product="edgar"
        selectProduct={selectProduct}
      />
    </div>
  );
};

const PaymentCycles = ({
  plans,
  currentPlan,
  selectedPlan,
  selectPaymentCycle,
  alignment,
  company
}) => {
  const selectedInterval =
    typeof selectedPlan === "string" ? selectedPlan : selectedPlan?.interval;

  return (
    <div
      className={classNames({
        [styles.centerPlans]: alignment === "center",
        [styles.leftPlans]: alignment === "left"
      })}
    >
      {plans.map(plan => {
        const sameInterval = selectedInterval === plan.interval;
        return (
          <PaymentCycle
            key={plan.stripeId}
            isCurrent={sameInterval}
            isSelectable
            isSelected={sameInterval}
            plan={plan}
            refPlan={currentPlan}
            selectPaymentCycle={selectPaymentCycle}
            company={company}
          />
        );
      })}
    </div>
  );
};

export default PlanSelector;
