/* @flow */
import { gql } from "@apollo/client";

export default gql`
  mutation createInstagramComment(
    $postId: Int!
    $commentBody: String!
    $commentId: String!
  ) {
    instagramCreateComment(
      postId: $postId
      commentBody: $commentBody
      commentId: $commentId
    ) {
      error
      id
    }
  }
`;
