/* @flow */
import { gql } from "@apollo/client";

export default gql`
  query getInvoice($invoiceId: String!) {
    invoice(invoiceId: $invoiceId) {
      id
      dueDate
      paid
      total
      coupon
      status
      subtotal
      lines{
        description
        amount
        unitAmount
        quantity
      }
    }
    user {
      id
      firstName
      lastName
      onboardingProgress {
        id
        addAccount
        viewCategories
        addContent
        addTimeslots
        importContent
        dismissedAt
      }
      company {
        id
        name
        queueStatus
        address1
        address2
        city
        region
        zip
        countryName
        additionalAccounts
        paymentSource {
          type
          last4
          expDisplay
        }
        subscriptionPlan {
          shouldDisplay11xUpsellCopy
          accountLimit
          name
          amount
        }
      }
    }
  }
`;
