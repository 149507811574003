/* @flow */
import { gql } from "@apollo/client";

export default gql`
  mutation updateShopifyAccount($params: ShopifyAccountInput!) {
    updateShopifyAccount(params: $params) {
      shopifyAccount {
        id
        storeUrl
        accessToken
        accountRelationships {
          id
        }
        categoryId
        importAttributes
        importContentAsPending
      }
    }
  }
`;
