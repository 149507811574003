/* @flow */
import { gql } from "@apollo/client";

export default gql`
  query getUpcomingPosts($filter: UpcomingPostFilter, $page: Page) {
    company {
      id
      queueStatus
      timeZone
    }
    user {
      id
      onboardingProgress {
        id
        addAccount
        viewCategories
        addContent
        addTimeslots
        importContent
        dismissedAt
      }
    }
    upcomingPosts(filter: $filter, page: $page) {
      data {
        id
        sendAt
        selectedViaRandom
        scheduled
        scheduleId
        text
        mentionNames
        rawRichTextEntityMap
        link
        useOnce
        sendMobileReminder
        linkPreview {
          url
          title
          description
          images {
            src
          }
        }
        images {
          url
        }
        videos {
          url
        }
        account {
          id
          name
          image
          nickname
          provider
          platform
          description
          isOk
        }
        category {
          id
          name
          colorIndex
        }
        content {
          id
          instaReels
          expiresAt
        }
        pinterestBoards {
          name
        }
      }
      emptyReasons
      totalCount
    }
  }
`;
