/* @flow */
import { gql } from "@apollo/client";

export default gql`
  mutation toggleNewUI($enable: Boolean!) {
    toggleNewUI(enable: $enable) {
      enabled
    }
  }
`;
