/* @flow */
import { gql } from "@apollo/client";

export default gql`
  mutation deleteContent($id: ID!) {
    deleteContent(id: $id) {
      content {
        id
      }
      errors
    }
  }
`;
