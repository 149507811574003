/* @flow */
import { gql } from "@apollo/client";

export default gql`
  mutation DeleteRssFeed($id: ID!) {
    deleteRssFeed(id: $id) {
      deletedId
      errors {
        field
        messages
      }
    }
  }
`;
