/* @flow */
import { gql } from "@apollo/client";

export default gql`
  mutation pauseQueue {
    pauseQueue {
      id
      queueStatus
    }
  }
`;
