/* @flow */
import { gql } from "@apollo/client";

export default gql`
  mutation updateLinkShortener($service: LinkShortener!) {
    updateLinkShortener(service: $service) {
      errors
    }
  }
`;
