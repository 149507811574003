/* @flow */
import { gql } from "@apollo/client";

export default gql`
  query getContentPerformance($id: ID!, $postsPage: Page) {
    content(id: $id) {
      id
      status
      queueable
      pinterestDestinationLink
      omitsPinterestDestinationLink
      twitterPostedVariationsCount
      sendAt
      createdAt
      expiresAt
      useOnce
      totalPostCount
      sendMobileReminder
      lastPostedTo {
        twitter {
          sentAt
          account {
            id
            name
            nickname
            providerDisplayName
            platform
            provider
            image
          }
        }
      }
      accountRelationships {
        id
        account {
          id
          name
          nickname
          providerDisplayName
          platform
          provider
          image
        }
      }
      category {
        name
        colorIndex
      }
      variations {
        id
        origin
        rawRichTextEntityMap
        reachedPostingLimit
        displayText
        displayFbText
        lastPostedTo {
          twitter {
            sentAt
            account {
              id
            }
          }
        }
        mentionNames
        shortLinks {
          id
          timesSent
          totalClicks
          averageClicksPerTimesSent
          url
          destination
          variation {
            id
          }
          clickStats {
            day
            totalClicks
          }
        }
        pinterestDestinationLink
        pinterestTitle
        images {
          url
        }
        videos {
          url
        }
        link
        pageScrape {
          url
          title
          description
          images {
            src
          }
        }
      }
      pinterestBoards {
        name
        account {
          id
        }
      }
      posts(page: $postsPage) {
        sendAt
        sentAt
        url
        videoStatus
        account {
          displayName
        }
        variation {
          id
        }
      }
    }
    user {
      id
      company {
        id
        timeZone
      }
      onboardingProgress {
        id
        addAccount
        viewCategories
        addContent
        addTimeslots
        importContent
        dismissedAt
      }
    }
  }
`;
