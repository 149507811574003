/* @flow */
import { gql } from "@apollo/client";

export default gql`
  mutation updateSchedule($input: UpdateScheduleInput!) {
    updateSchedule(input: $input) {
      schedule {
        id
      }
      errors
    }
  }
`;
