import { gql } from "@apollo/client";

export const ACCEPT_INVITATION = gql`
  mutation acceptInvitation($input: AcceptInvitationInput!) {
    acceptInvitation(input: $input) {
      user {
        email
      }
      errors {
        field
        messages
      }
    }
  }
`;
