/* @flow */
import { gql } from "@apollo/client";

export default gql`
  mutation reactivateSubscription {
    reactivateSubscription {
      errors
    }
  }
`;
