import { InMemoryCache, ApolloLink, ApolloClient } from "@apollo/client";
import { createConsumer } from "@rails/actioncable";
import ActionCableLink from "graphql-ruby-client/subscriptions/ActionCableLink";

const cable = createConsumer();

// const httpLink = new HttpLink({
//   uri: "/graphql",
//   credentials: "include"
// });

const hasSubscriptionOperation = ({ query: { definitions } }) => {
  return definitions.some(
    ({ kind, operation }) =>
      kind === "OperationDefinition" && operation === "subscription"
  );
};

const link = ApolloLink.split(
  hasSubscriptionOperation,
  new ActionCableLink({ cable })
  // httpLink
);

export const client = new ApolloClient({
  uri: "/graphql",
  link: link,
  cache: new InMemoryCache()
});
