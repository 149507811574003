/* @flow */
import { gql } from "@apollo/client";

export default gql`
  query aiConversations {
    aiConversations {
      contentLimit
      conversations {
        id
        messages{
          id
          message
        }
        prompt
      }
    }
  }
`;