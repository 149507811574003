/* @flow */
import { gql } from "@apollo/client";

export default gql`
  mutation createMentionTagMap($input: CreateMentionTagMapInput!) {
    createMentionTagMap(input: $input) {
      mentionTagMap {
        id
        provider
        displayName
      }
      errors
    }
  }
`;
