/* @flow */
import { gql } from "@apollo/client";

export default gql`
  query getInstagramConversations($accountId: Int!, $cursor: String) {
    fetchInstagramConversations(accountId: $accountId, cursor: $cursor) {
      conversations {
        id
        link
        name
        receiver {
          id
          name
          profilePic
        }
        sender {
          id
          name
          profilePic
        }
        snippet
        unreadCount
        updatedTime
      }
      cursor
      error
    }
  }
`;
