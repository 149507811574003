/* @flow */
import { gql } from "@apollo/client";

export default gql`
  query dashboardCard(
    $startDate: ISO8601Date!
    $endDate: ISO8601Date!
    $cardType: String!
    $accountId: Int
  ) {
    dashboardCard(
      input: {
        startDate: $startDate
        endDate: $endDate
        cardType: $cardType
        accountId: $accountId
      }
    ) {
      cardType
      lastPeriodTrend
      cardValue
    }
  }
`;
