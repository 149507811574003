import styled, { css } from 'styled-components';

export const Container = styled.div`
    font-family: Arial, sans-serif;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width:100%;
    margin-top:20px;
`;


export const Section = styled.div`
    margin-bottom: 20px;
    ${props => props.row && css`
        display: flex;
        flex-wrap: wrap;
        gap:5%
    `}
`;

export const SectionVideoContent = styled.div`
    margin-bottom: 20px;
    width:40%;
    ${props => props.row && css`
        display: flex;
        flex-wrap: wrap;
    `}
`;

export const Input = styled.input`
    width: 100%;
    padding: 8px;
    margin-top: 5px;
    border-radius: 4px;
    border: 1px solid #ccc;
`;

export const Select = styled.select`
    padding: 8px;
    margin-top: 5px;
    border-radius: 4px;
    border: 1px solid #ccc;
    background-color: white;
`;

export const PaddedDiv = styled.div`
    @media (max-width: 768px)
    {
        width: 100%;
    }
`;
export const MarginedDiv = styled.div`
    width:100%;
    margin-top:30px;
    
`

export const PrivacyStatus = styled.div`
        width:30%;
`
export const Option = styled.option`
    background-color: white;
    font-size: 16px;
    line-height: 24px;
`;

export const Fieldset = styled.fieldset`
    padding-top: 8px;
    padding-left: 0px;
    border:none;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
`;


export const FlexContainer = styled.div`
        @media (max-width: 768px)
        {
            width: 100%;
        }
`;

export const CommercialOptions = styled.div`
    margin-top: 10px;
    padding-left: 0;

    label {
        padding-left: 0;
        border: 1px solid #E4E4E4;
        border-radius: 6px;

        .upperHead {
          padding: 20px;

          h3 {
              margin: 0;
              display: flex;
              align-items: center;
              gap: 10px;
          }

          p {
              margin: 10px 0 0;
          }

          .brand-icon {
            display: flex;
            gap: 10px;

            img {
                width: 22px;
            }
          }
        }

        .bottomArea {
          border-top: 1px solid #E4E4E4;
          padding: 10px 20px;
          background-color: rgba(51, 139, 131, 0.08);
          display: flex;
          align-items: center;
          justify-content: space-between;

          span {
            color: rgba(51, 139, 131, 1);
          }

          svg {
            width: 15px;
            height: 16px;

            path {
                fill: rgba(51, 139, 131, 1);
            }
          }
        }

        .checkmark {
            left: auto;
            right: 20px;
            top: 20px;
        }
    }
`;

export const CheckboxContainer = styled.label`
  display: block;
  position: relative;
  padding-left: 32px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: 3px;
    left: 0;
    height: 17px;
    width: 17px;
    background-color: #eee;
    border-radius: 4px;
    border: 1px solid #ccc;
  }

  &:hover input ~ .checkmark {
    background-color: #ccc;
  }

  .checkmark::after {
    content: "";
    position: absolute;
    display: none;
  }

  input:checked ~ .checkmark::after {
    display: block;
  }

  .checkmark::after {
    left: 4px;
    top: 1px;
    width: 4px;
    height: 9px;
    border: solid var(--kelp400);
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
     ${({ disabled }) => disabled && css`
        cursor: not-allowed;
        opacity: var(--disabledOpacity);

        .checkmark {
            cursor: not-allowed;
            background-color: #f0f0f0;
            border-color: #ddd;
        }

        &:hover {
            cursor: not-allowed;
        }
    `}
`;


export const ToggleContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
`;

export const ToggleLabel = styled.label`
    font-weight: bold;
    font-size: 16px;
`;

export const ToggleSwitch = styled.input`
    width: 40px;
    height: 20px;
    appearance: none;
    background-color: #ccc;
    border-radius: 20px;
    position: relative;
    outline: none;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:checked {
        background-color: var(--kelp400);
    }

    &:checked::before {
        transform: translateX(20px);
    }

    &::before {
        content: "";
        position: absolute;
        width: 18px;
        height: 18px;
        border-radius: 50%;
        background: white;
        top: 1px;
        left: 1px;
        transition: transform 0.3s ease;
    }
`;

export const InfoBox = styled.div`
    background-color: #e6f0ff;
    color: #333;
    padding: 10px;
    border-radius: 8px;
    font-size: 14px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;

    &::before {
        content: "ℹ️";
        margin-right: 8px;
        font-size: 15px;
    }
`;

export const Description = styled.p`
    font-size: 14px;
    color: #666;
    margin: 10px 0;
`;

export const FooterText = styled.p`
    font-size: 12px;
    color: #666;
    margin-top: 20px;

    a {
        color: #00ccff;
        text-decoration: none;
    }
`;

export const Prompt = styled.div`
background-color: #e6f0ff;
color: #333;
padding: 10px;
border-radius: 8px;
font-size: 14px;
margin-top: 10px;
`;