/* @flow */
import { gql } from "@apollo/client";

export default gql`
  mutation deleteInvitation($input: DeleteInvitationInput!) {
    deleteInvitation(input: $input) {
      errors {
        field
        messages
      }
    }
  }
`;
