/* @flow */
import { gql } from "@apollo/client";

export default gql`
  query loadFilters {
    categories {
      id
      name
      colorIndex
    }
    accounts {
      id
      name
      nickname
      displayName
      providerDisplayName
      platformDisplayName
      image
      platform
      provider
    }
    rssFeeds {
      id
      name
      url
    }
    teamMembers {
      id
      email
    }
  }
`;
