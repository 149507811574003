/* @flow */
import { gql } from "@apollo/client";

export default gql`
  mutation deleteTeamMember($input: DeleteTeamMemberInput!) {
    deleteTeamMember(input: $input) {
      errors {
        field
        messages
      }
    }
  }
`;
