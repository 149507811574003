/* @flow */
import { gql } from "@apollo/client";

export default gql`
  query getRssFeeds {
    rssFeeds {
      id
      name
      lastImportedAt
      url
      category {
        id
        name
        colorIndex
      }
      accounts {
        id
        provider
        platform
      }
      useOnce
      requiresApproval
    }
  }
`;
