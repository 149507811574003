/* @flow */
import { gql } from "@apollo/client";

export default gql`
  query getDashboard {
    user {
      id
      email
      createdAt
      company {
        id
        instagramPersonalAccounts
      }
      hideOldViewButton
      dismissedInformationBoxes
      cardData
      onboardingProgress {
        id
        addAccount
        viewCategories
        addContent
        addTimeslots
        importContent
        dismissedAt
      }
    }
  }
`;
