/* @flow */
import React from "react";
import moment from "moment";
import DatePicker from "react-datepicker";

import { Label } from "typography";

import SVGIcon from "./icon.svg";

import useSendOn from "./use";
import { Value } from "./styles";
import styles from "./index.css";
import type { IProps } from "./types.js";

import { Wrapper, LabelIcon } from "../SendAt/styles";

const ScheduleSendOn = (props: IProps) => {
  const { readOnly } = props;
  const { handleChange } = useSendOn(props);

  const startAtMoment = moment(props.startAt.substring(0, 10));

  return (
    <Wrapper>
      <LabelIcon>
        <img src={SVGIcon} alt="send on icon" />
      </LabelIcon>
      <Label htmlFor="sendOn">Send on</Label>

      {readOnly ? (
        <Value className={readOnly ? "readOnly" : ""}>
          {startAtMoment.format("dddd, MMM D")}
        </Value>
      ) : (
        <DatePicker
          customInput={<Value>{startAtMoment.format("dddd, MMM D")}</Value>}
          // $FlowFixMe
          selected={startAtMoment.clone().toDate()}
          minDate={moment().toDate()}
          autoComplete="off"
          popperPlacement="bottom"
          popperClassName={styles.popper}
          onChange={handleChange}
        />
      )}
    </Wrapper>
  );
};

export default ScheduleSendOn;
