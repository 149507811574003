/* @flow */
import { gql } from "@apollo/client";

export default gql`
  query getAccountCancellationConfirmationData {
    user {
      company {
        queueStatus
      }
    }
  }
`;
