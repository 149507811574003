/* @flow */
import React, { useState } from "react";

import Modal from "layout/modals/Modal";
import Button from "buttons/Button";
import Icon from "icons/Icon";
import PaymentMethod from "components/PaymentMethod";

import type { FlashType } from "reducers/flash";
import type {
  updatePaymentMethod as UpdatePaymentMethodResult,
  updatePaymentMethodVariables
} from "graphql-types/updatePaymentMethod";

import styles from "./index.css";

type Props = {
  open: boolean,
  flash: (type: FlashType, body: string) => void,
  updatePaymentMethod?: ({
    variables: updatePaymentMethodVariables
  }) => Promise<{ data: UpdatePaymentMethodResult }>,
  updatePaymentMethodLoading?: boolean,
  onOpen: () => void,
  onClose: () => void
};

const UpdatePaymentMethod = ({
  open,
  flash,
  updatePaymentMethod,
  updatePaymentMethodLoading,
  onOpen,
  onClose
}: Props) => {
  const [isPaymentMethodComplete, setIsPaymentMethodComplete] = useState(false);
  const [token, setToken] = useState(null);
  return (
    <>
      {open && (
        <Modal
          closeOnOverlayClick
          footer={
            <>
              <Button
                className={styles.buttonCancel}
                onClick={onClose}
                type="secondary"
              >
                Cancel
              </Button>
              <Button
                disabled={
                  !isPaymentMethodComplete || updatePaymentMethodLoading
                }
                onClick={async (): Promise<void> => {
                  if (!token) {
                    return;
                  }
                  const promise = updatePaymentMethod?.({
                    variables: { paymentMethod: { token: token.id } }
                  });
                  if (promise) {
                    promise.then(response => {
                      const errors =
                        response?.data?.updatePaymentMethod?.errors || [];
                      if (errors.length > 0) {
                        flash("error", errors[0]);
                      } else {
                        flash(
                          "notice",
                          "Payment method has been successfully updated!"
                        );
                      }
                      onClose();
                    });
                  }
                }}
                type="primary"
              >
                Apply
              </Button>
            </>
          }
          onClose={onClose}
          title="Update payment information"
        >
          <div className={styles.main}>
            <span className={styles.security}>
              <Icon type="lock" />
              Secure 128-bit encryption
            </span>
            <PaymentMethod
              setToken={setToken}
              errors={[]}
              onComplete={() => setIsPaymentMethodComplete(true)}
              onIncomplete={() => setIsPaymentMethodComplete(false)}
            />
          </div>
        </Modal>
      )}
      <Button
        className={styles.sectionButton}
        onClick={evt => {
          evt.preventDefault();
          onOpen();
        }}
        type="primary"
      >
        Edit details
      </Button>
    </>
  );
};

export default UpdatePaymentMethod;
