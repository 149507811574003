/* @flow */
import { gql } from "@apollo/client";

export default gql`
  query searchSocialMedia($provider: Provider!, $query: String!) {
    searchSocialMedia(provider: $provider, query: $query) {
      id
      name
      displayName
      verified
      verificationStatus
      provider
      profileImage
    }
  }
`;
