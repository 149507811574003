/* @flow */
import React, { useEffect, useState, useCallback } from "react";

import H3 from "typography/H3";
import LoadingIcon from "icons/LoadingIcon";
import Button from "buttons/Button";
import Label from "typography/Label";
import { featureFlag } from "util";

import PlanSelector from "./PlanSelector";
import Product from "./Product";
import Usage from "./Usage";
import Discount from "./Discount";
import CouponModal from "./CouponModal";

import type { FlashType } from "reducers/flash";
import type {
  getUserSettings_user_company as Company,
  getUserSettings_user_company_subscriptionPlan as SubscriptionPlan
} from "graphql-types/getUserSettings";
import type {
  updateSubscription as UpdateSubscriptionResult,
  updateSubscriptionVariables
} from "graphql-types/updateSubscription";
import type { reactivateSubscription as ReactivateSubscriptionResult } from "graphql-types/reactivateSubscription";

import styles from "./index.css";

type OwnProps = {
  company: Company,
  couponCode?: string,
  flash: (type: FlashType, body: string) => void,
  isAnnualTrialPriceHintDisplayed?: boolean,
  isPlanSwitchable?: boolean,
  reactivateSubscription?: () => Promise<{
    data: ReactivateSubscriptionResult
  }>,
  reactivateSubscriptionLoading?: boolean,
  updateSubscription?: ({
    variables: updateSubscriptionVariables
  }) => Promise<{ data: UpdateSubscriptionResult }>,
  updateSubscriptionLoading?: boolean
};

type Props = {
  closePlanSelector?: () => void,
  isPlanSelectorOpen?: boolean,
  openPlanSelector?: () => void,
  selectPlan?: (selectedPlan: SubscriptionPlan) => void,
  selectedPlan?: ?SubscriptionPlan
} & OwnProps;

export const HeaderButton = ({
  company,
  isPlanSelectorOpen,
  openPlanSelector,
  reactivateSubscriptionLoading,
  updateSubscriptionLoading
}: Props) => {
  if (
    !company ||
    !company.availableSubscriptionPlans ||
    company.availableSubscriptionPlans.length < 1 ||
    updateSubscriptionLoading
  ) {
    return null;
  }
  return (
    <Button
      className={styles.button}
      disabled={isPlanSelectorOpen || reactivateSubscriptionLoading}
      onClick={openPlanSelector}
      type="primary"
    >
      Change subscription
    </Button>
  );
};

export const Header = (props: Props) => {
  return (
    <div className={styles.header}>
      <H3>Subscription</H3>
      <HeaderButton {...props} />
    </div>
  );
};

export const CurrentPlan = ({
  company,
  flash,
  isPlanSelectorOpen,
  reactivateSubscription,
  reactivateSubscriptionLoading
}: Props) => {
  if (isPlanSelectorOpen || !company) {
    return null;
  }
  return (
    <>
      <div className={styles.plans}>
        <Product
          isCurrent
          product={company?.subscriptionPlan?.productName}
          stripeId={company?.subscriptionPlan?.stripeId}
        />
        {featureFlag("new_billing_ui") && company.subscribed && <Usage />}
      </div>
      {company.subscriptionEndsAt && !company?.shopifyOrigin && (
        <div className={styles.row}>
          <Label className={styles.label}>Billing period end date</Label>
          <span>{company.subscriptionEndsAt}</span>
          <Button
            className={`${styles.button} ${styles.pushRight}`}
            disabled={reactivateSubscriptionLoading}
            onClick={(): void => {
              const promise = reactivateSubscription?.();
              if (promise) {
                promise.then(
                  (response): void => {
                    const errors =
                      response?.data?.reactivateSubscription?.errors || [];
                    if (errors.length > 0) {
                      flash("error", errors[0]);
                    } else {
                      flash(
                        "notice",
                        "You reactivated your subscription successfully."
                      );
                    }
                  }
                );
              }
            }}
            type="primary"
          >
            Reactivate subscription
          </Button>
        </div>
      )}
    </>
  );
};

export const Body = (props: Props) => {
  const [showCouponModal, setShowCouponModal] = useState(false);
  const [couponCode, setCouponCode] = useState();
  if (props.updateSubscriptionLoading || props.reactivateSubscriptionLoading) {
    return <LoadingIcon className={styles.loading} />;
  }
  if (!props.selectedPlan || !props.company) {
    return <span>Oops! No subscription data available!</span>;
  }

  return (
    <>
      <Label className={styles.subheader}>
        {props.isPlanSelectorOpen && props.company.upgradeAvailable
          ? "Choose your plan"
          : "Plan"}
      </Label>
      {props.isAnnualTrialPriceHintDisplayed && (
        <p className={styles.annualTrialPriceHint}>
          You won’t be charged until your 30-day trial ends &ndash; and you can
          cancel at any time.
        </p>
      )}
      <CurrentPlan {...props} />

      <PlanSelector {...props} couponCode={couponCode} />

      {props.isPlanSelectorOpen && props.company.upgradeAvailable && (
        <div className={styles.row}>
          {showCouponModal && (
            <CouponModal
              couponCode={couponCode}
              onApplyCoupon={code => {
                setCouponCode(code);
                setShowCouponModal(false);
              }}
              onClickHideCouponModal={() => setShowCouponModal(false)}
            />
          )}

          {!props.company?.shopifyOrigin && (
            <Discount
              couponCode={couponCode}
              onClickShowCouponModal={() => setShowCouponModal(true)}
            />
          )}
        </div>
      )}
      <div className={styles.row}>
        <Button
          className={`${styles.button} ${styles.pushRight}`}
          href="/cancellation/new"
          type="light"
        >
          Close my account
        </Button>
      </div>
    </>
  );
};

const Manager = (props: Props) => {
  const { company } = props;
  const { subscriptionPlan } = company;

  const [isPlanSelectorOpen, setIsPlanSelectorOpen] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(subscriptionPlan);

  // if any properties from the parent component change, ensure that we update
  //  key properties on this child component with useEffect()
  useEffect(() => {
    setSelectedPlan(subscriptionPlan);
  }, [setSelectedPlan, subscriptionPlan]);

  const handleClosePlanSelector = useCallback(() => {
    // rollback any changes that may be present in plans, products, or payment cycles
    // setting selectedPlan to null, and using nullish coalescing op when passing a plan
    //  in to <Body/> ensures that children always have the correct plan
    // previously, setting the plan to subscriptionPlan in this function was failing
    //  as it was not using the updated object returned by graphql after a subscription
    //  had been upgraded; the same also works when the user hits the cancel button
    //  - changes are rolled back as expected
    setIsPlanSelectorOpen(false);
    setSelectedPlan(null);
  }, [setIsPlanSelectorOpen, setSelectedPlan]);

  const handleOpenPlanSelector = useCallback(() => {
    setIsPlanSelectorOpen(true);
  }, [setIsPlanSelectorOpen]);

  return (
    <div className={styles.manager}>
      <Header
        {...props}
        isPlanSelectorOpen={isPlanSelectorOpen}
        openPlanSelector={handleOpenPlanSelector}
      />
      <Body
        {...props}
        isPlanSelectorOpen={isPlanSelectorOpen}
        closePlanSelector={handleClosePlanSelector}
        selectedPlan={selectedPlan ?? subscriptionPlan}
        selectPlan={setSelectedPlan}
      />
    </div>
  );
};

export default (ownProps: OwnProps) => {
  return <Manager {...ownProps} />;
};
