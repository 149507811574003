/* @flow */
import { gql } from "@apollo/client";

export default gql`
  mutation removeDevice($deviceId: Int!) {
    removeDevice(deviceId: $deviceId) {
      errors
    }
  }
`;
