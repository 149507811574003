/* @flow */
import { gql } from "@apollo/client";

export default gql`
  query getCategoryContents($filter: ContentsFilter, $page: Page) {
    contents(filter: $filter, page: $page) {
      data {
        id
        status
        queueable
        sendAt
        expiresAt
        useOnce
        sendMobileReminder
        twitterPostedVariationsCount
        lastPostedTo {
          twitter {
            sentAt
            account {
              id
              name
              nickname
              providerDisplayName
              platform
              provider
              image
            }
          }
        }
        accountRelationships {
          id
          account {
            id
            name
            nickname
            providerDisplayName
            platform
            provider
            image
          }
        }
        category {
          name
          colorIndex
        }
        variations {
          id
          origin
          rawRichTextEntityMap
          displayText
          displayFbText
          lastPostedTo {
            twitter {
              sentAt
            }
          }
          reachedPostingLimit
          mentionNames
          pinterestDestinationLink
          pinterestTitle
          images {
            url
          }
          videos {
            url
          }
          link
          pageScrape {
            url
            title
            description
            images {
              src
            }
          }
        }
        pinterestBoards {
          name
          account {
            id
          }
        }
      }
      totalContentsForStatus
      totalFilteredContents
      pendingCount
    }
    rssFeeds {
      id
    }
    # Add id to the user and company types to avoid an Apollo caching conflict with
    # getLibraryStatus query.
    #
    # @see https://stackoverflow.com/q/44403930/1858091
    user {
      id
      onboardingProgress {
        id
        addAccount
        viewCategories
        addContent
        addTimeslots
        importContent
        dismissedAt
      }
    }
    company {
      id
      timeZone
      queueStatus
    }
  }
`;
