/* @flow */
import { gql } from "@apollo/client";

export default gql`
  mutation updateInvitation($input: UpdateTeamMemberInput!) {
    updateTeamMember(input: $input) {
      errors {
        field
        messages
      }
    }
  }
`;
