import React, { useRef, useEffect, useLayoutEffect } from "react";
import styles from "./index.css";
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { createFlash } from "../../../../../../actions/flash";
import { connect } from "react-redux";

const ConversationContainer = props => {
  const { aiContent, flash } = props;
  const containerRef = useRef(null); // Create a ref for the container

  useLayoutEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  }, [aiContent]);

  const copyToClipboard = (text) => {
    if (navigator.clipboard) { // Modern async clipboard API
      navigator.clipboard.writeText(text).then(() => {
        console.log("Text copied to clipboard");
        flash("notice", "Text copied to clipboard");
      }).catch(err => {
        console.error("Failed to copy text: ", err);
      });
    } else { // Fallback for older browsers
      const textArea = document.createElement("textarea");
      textArea.value = text;
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      try {
        document.execCommand('copy');
        console.log("Text copied to clipboard");
        flash("notice", "Text copied to clipboard");
      } catch (err) {
        console.error("Failed to copy text: ", err);
      }
      document.body.removeChild(textArea);
    }
  };

  return (
    <div className={styles.conversationContainter} ref={containerRef}>
      {aiContent.map(content => (
        <div key={content.id}>
          <div className={styles.selectAiPromptContainer}>
            {content.prompt}
            <div className={styles.userAvatar} />
          </div>
          <div className={styles.aiMessageDivider} />

          <div className={styles.responseAiPromptContainer}>
            <div className={styles.aiAvatar} />
            Sure, here are some content options for the topic you've requested
          </div>
          {content.messages.map(message => (
            <div 
              key={message.id} 
              className={styles.selectAiResponseContainer}
              onClick={() => copyToClipboard(message.message)}
              data-for="messages" 
              data-tip={"Click here to copy"}
            >
              {message.message}
              <ReactTooltip
                effect="solid"
                place="top"
                type="info"
                backgroundColor="#000"
                id="messages"
              />
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};



const mapDispatchToProps = dispatch => {
  return {
    flash(type, body) {
      dispatch(createFlash(type, body));
    }
  };
};

export default connect(
  null,
  mapDispatchToProps
)(ConversationContainer);