/* @flow */
import { gql } from "@apollo/client";

export default gql`
  mutation approveAllContent($filter: ContentsFilter!) {
    approveAllContent(filter: $filter) {
      contents {
        id
        status
      }
      errors
    }
  }
`;
